var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Hosts")]),
      _c(
        "rtb-card",
        [
          _c("rtb-card-body", [
            _c(
              "div",
              { staticClass: "rtb-d-flex align-center" },
              [
                _c(
                  "rtb-button",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "success" },
                    on: { click: _vm.initHostCreation },
                  },
                  [
                    _c("svg-icon", { attrs: { name: "plus-regular" } }),
                    _vm._v("Add new Host"),
                  ],
                  1
                ),
                _c("rtb-text-input", {
                  attrs: {
                    label: "Search",
                    placeholder: "Search",
                    "hide-label": "",
                  },
                  model: {
                    value: _vm.query,
                    callback: function ($$v) {
                      _vm.query = $$v
                    },
                    expression: "query",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("rtb-table"),
      _c("v-data-table", {
        staticClass: "rtb-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.usersList,
          pagination: _vm.pagination,
          search: _vm.query,
          "item-key": "id",
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(item.id))]),
                  _c(
                    "td",
                    [
                      _c("text-highlight", { attrs: { queries: _vm.query } }, [
                        _vm._v(
                          " " +
                            _vm._s(item.firstname) +
                            " " +
                            _vm._s(item.lastname) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { attrs: { width: "108px" } },
                    [
                      _c(
                        "rtb-button",
                        {
                          staticClass: "mr-1",
                          attrs: { variant: "icon", "aria-label": "Edit" },
                          on: {
                            click: function ($event) {
                              return _vm.edit(item)
                            },
                          },
                        },
                        [_c("svg-icon", { attrs: { name: "pen-regular" } })],
                        1
                      ),
                      _c(
                        "rtb-button",
                        {
                          attrs: {
                            color: "danger",
                            variant: "icon",
                            "aria-label": "delete",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openRemoveDialog(item)
                            },
                          },
                        },
                        [_c("svg-icon", { attrs: { name: "trash-regular" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "no-click-animation": "",
            "max-width": "500px",
          },
          model: {
            value: _vm.deleting,
            callback: function ($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting",
          },
        },
        [
          !!_vm.host
            ? _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [_c("v-toolbar-title", [_vm._v(" Delete Host")])],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(" Are you sure want to delete the host "),
                    _c("b", [_vm._v(_vm._s(_vm.host.firstname))]),
                    _vm._v("? "),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "" } },
                        [
                          _vm.isSuper
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "error",
                                  attrs: { disabled: _vm.working, flat: "" },
                                  on: { click: _vm.remove },
                                },
                                [_vm._v(" DELETE ")]
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: { disabled: _vm.working, flat: "" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "no-click-animation": "", width: "768px" },
          model: {
            value: _vm.editing,
            callback: function ($$v) {
              _vm.editing = $$v
            },
            expression: "editing",
          },
        },
        [
          _c("validation-observer", {
            ref: "observer",
            attrs: { slim: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var invalid = ref.invalid
                  return [
                    _vm.host
                      ? _c(
                          "rtb-card",
                          [
                            _c(
                              "rtb-card-body",
                              [
                                _c(
                                  "rtb-row",
                                  [
                                    _c(
                                      "rtb-col",
                                      { attrs: { xs: "4" } },
                                      [
                                        _c("rtb-text-input", {
                                          attrs: {
                                            label: "First Name",
                                            rules: _vm.getRules().HostFirstName,
                                          },
                                          model: {
                                            value: _vm.host.firstname,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.host,
                                                "firstname",
                                                $$v
                                              )
                                            },
                                            expression: "host.firstname",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "rtb-col",
                                      { attrs: { xs: "4" } },
                                      [
                                        _c("rtb-text-input", {
                                          attrs: {
                                            label: "Last Name",
                                            rules: _vm.getRules().HostLastName,
                                          },
                                          model: {
                                            value: _vm.host.lastname,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.host,
                                                "lastname",
                                                $$v
                                              )
                                            },
                                            expression: "host.lastname",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "rtb-col",
                                      { attrs: { xs: "4" } },
                                      [
                                        _c("rtb-text-input", {
                                          attrs: {
                                            label: "Role",
                                            rules: _vm.getRules().HostRole,
                                          },
                                          model: {
                                            value: _vm.host.role,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.host, "role", $$v)
                                            },
                                            expression: "host.role",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "rtb-col",
                                      { attrs: { xs: "4" } },
                                      [
                                        _c("rtb-text-input", {
                                          attrs: {
                                            label: "Email",
                                            rules: _vm.getRules().HostEmail,
                                          },
                                          model: {
                                            value: _vm.emailComputed,
                                            callback: function ($$v) {
                                              _vm.emailComputed = $$v
                                            },
                                            expression: "emailComputed",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "rtb-col",
                                      { attrs: { xs: "4" } },
                                      [
                                        _c("rtb-text-input", {
                                          attrs: {
                                            label: "Image URL",
                                            rules: _vm.getRules().HostImageURL,
                                          },
                                          model: {
                                            value: _vm.host.image,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.host, "image", $$v)
                                            },
                                            expression: "host.image",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "rtb-col",
                                      { attrs: { xs: "4" } },
                                      [
                                        _c("rtb-text-input", {
                                          attrs: {
                                            autocomplete: "off",
                                            type: "text",
                                            label: "Venmo QR URL",
                                          },
                                          model: {
                                            value: _vm.host.venmo,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.host, "venmo", $$v)
                                            },
                                            expression: "host.venmo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "rtb-col",
                                      { attrs: { xs: "4" } },
                                      [
                                        _c("rtb-text-input", {
                                          attrs: {
                                            autocomplete: "off",
                                            type: "text",
                                            label: "PayPal",
                                          },
                                          model: {
                                            value: _vm.host.paypal,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.host, "paypal", $$v)
                                            },
                                            expression: "host.paypal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.adding
                                  ? _c(
                                      "rtb-row",
                                      [
                                        _c(
                                          "rtb-col",
                                          { attrs: { xs: "4" } },
                                          [
                                            _c("rtb-text-input", {
                                              attrs: {
                                                vid: "password",
                                                type: "password",
                                                label: "Password",
                                                placeholder: "********",
                                                autocomplete: "new-password",
                                                rules: _vm.getRules().Password,
                                              },
                                              model: {
                                                value: _vm.password,
                                                callback: function ($$v) {
                                                  _vm.password = $$v
                                                },
                                                expression: "password",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "rtb-col",
                                          { attrs: { xs: "4" } },
                                          [
                                            _c("rtb-text-input", {
                                              attrs: {
                                                type: "password",
                                                label: "Password confirm",
                                                placeholder: "********",
                                                autocomplete: "new-password",
                                                rules: Object.assign(
                                                  {},
                                                  _vm.getRules().Password,
                                                  { password: "@password" }
                                                ),
                                              },
                                              model: {
                                                value: _vm.passwordConfirm,
                                                callback: function ($$v) {
                                                  _vm.passwordConfirm = $$v
                                                },
                                                expression: "passwordConfirm",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.user.super
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("rtb-checkbox", {
                                          attrs: { label: "Super Admin" },
                                          model: {
                                            value: _vm.host.super,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.host, "super", $$v)
                                            },
                                            expression: "host.super",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "rtb-card-actions",
                              [
                                _vm.lastKnownGame
                                  ? _c(
                                      "span",
                                      { staticClass: "mr-auto" },
                                      [
                                        _vm._v("Last Know Game: "),
                                        _c(
                                          "rtb-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "dashboard.games",
                                                query: {
                                                  id: _vm.lastKnownGame.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.lastKnownGame.name)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.working
                                  ? _c("rtb-spinner", { staticClass: "mr-2" })
                                  : _vm._e(),
                                _c(
                                  "rtb-button",
                                  {
                                    attrs: {
                                      color: "dark",
                                      disabled: _vm.working,
                                    },
                                    on: { click: _vm.cancel },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "rtb-button",
                                  {
                                    attrs: { disabled: invalid || _vm.working },
                                    on: { click: _vm.update },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }