











































































































































































































// TODO(Andrew): remove when time comes
// @ts-nocheck
import { db } from "@/firebase"

import Vue from "vue"
import TextHighlight from "vue-text-highlight"
import { mapActions, mapGetters } from "vuex"
import { ValidationObserver } from "vee-validate"
import { Role } from "@/helpers"
import { DEFAULT_IMAGE_PROFILE_URL } from "@/config"
import {
  fetchUsersByRole,
  fetchUserEmail,
  fetchUserIDByEmail,
  fetchUser
} from "@/services/user.service"
import { fetchGame, fetchOrgs } from "@/services/game.service"

import {
  RtbRow,
  RtbCol,
  RtbButton,
  RtbLink,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbSelect,
  RtbCheckbox,
  RtbTable,
  RtbSpinner
} from "@/components/ui"

import rules from "@/config/rules"
import UserService from "@/services/user.service"

const HEADERS = [
  {
    text: "ID",
    value: "id",
    sortable: false
  },
  {
    text: "Name",
    value: "firstname",
    sortable: false
  },
  {
    text: "Action",
    value: "action",
    sortable: false
  }
]

const DEFAULT_USER_STATE = {
  firstname: "",
  lastname: "",
  role: Role.Host
}

export default Vue.extend({
  name: "HostsPage",
  components: {
    TextHighlight,
    ValidationObserver,
    RtbRow,
    RtbCol,
    RtbButton,
    RtbLink,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbTextInput,
    RtbSelect,
    RtbCheckbox,
    RtbTable,
    RtbSpinner
  },
  data() {
    return {
      working: false,
      host: null,
      email: "",
      password: "",
      passwordConfirm: "",
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      query: "",
      headers: HEADERS,
      users: null,
      lastKnownGame: null,
      pagination: { rowsPerPage: 20 }
    }
  },
  computed: {
    ...mapGetters(["orgID", "user"]),
    ...mapGetters("auth", ["token", "isSuper"]),
    emailComputed: {
      set(value) {
        this.email = (value ? String(value) : "").toLowerCase()
      },
      get() {
        return (this.email ? String(this.email) : "").toLowerCase()
      }
    },
    usersList() {
      return Object.entries(this.users || {}).map(([id, user]) => ({
        ...user,
        id
      }))
    }
  },
  async mounted() {
    this.users = await fetchUsersByRole(Role.Host)
  },
  methods: {
    ...mapActions("auth", ["signUserUp"]),
    async updateUsers() {
      this.users = await fetchUsersByRole(Role.Host)
    },
    cancel() {
      this.emailComputed = null
      this.password = null
      this.passwordConfirm = null
      this.host = null
      this.deleting = false
      this.editing = false
      this.adding = false
      this.working = false
    },
    openRemoveDialog(user) {
      this.host = { ...user }
      this.deleting = true
    },
    async remove() {
      try {
        this.working = true
        await UserService.remove(this.host?.id)
        await this.updateUsers()
      } catch (e) {
        console.error(e)
        alert(e)
      } finally {
        this.working = false
        this.cancel()
      }
    },
    async edit(user) {
      this.working = true
      this.host = { ...user }
      this.emailComputed = await fetchUserEmail(user.id)
      const game = await fetchGame({
        orgID: user.orgID,
        gameID: user.gameID
      })
      if (game && game.name) {
        this.lastKnownGame = game
      }
      this.editing = true
      this.working = false
    },
    initHostCreation() {
      this.host = { ...DEFAULT_USER_STATE }
      this.editing = true
      this.adding = true
    },
    async update() {
      this.working = true
      const valid = await this.$refs.observer.validate()
      const email = this.emailComputed

      if (valid) {
        try {
          const newUser = {
            ...this.host,
            createdBy: this.user.firstname
          }

          if (this.adding) {
            const existingUserID = await fetchUserIDByEmail(email)
            if (existingUserID) {
              const user = await fetchUser({ userID: existingUserID })

              if (user?.role === Role.Host) {
                throw new Error(
                  `Email ${email} is assigned to ${user.firstname} ${user.lastname} (ID ${existingUserID})`
                )
              } else {
                if (
                  user &&
                  confirm(
                    `Email ${email} is assigned to ${user.firstname} ${user.lastname} (ID ${existingUserID}). Would you like to give them "${newUser.role}" permissions?`
                  )
                ) {
                  await db.ref(`access/1/${existingUserID}`).set(true)
                  await this.$store.dispatch("updateHost", {
                    ...(user || newUser),
                    id: existingUserID,
                    role: newUser.role
                  })
                  await this.updateUsers()
                  return this.cancel()
                } else {
                  throw new Error("Aborted.")
                }
              }
            }
            const registerUser = {
              firstname: newUser.firstname,
              lastname: newUser.lastname,
              image: newUser.image || DEFAULT_IMAGE_PROFILE_URL,
              email,
              password: this.password,
              gameID: null,
              clientID: null,
              audit: false,
              trusted: true
            }
            const userID = await this.signUserUp(registerUser)
            if (newUser.super) {
              await db.ref(`access/0/${userID}`).set(true)
            } else {
              await db.ref(`access/1/${userID}`).set(true)
            }
            await this.$store.dispatch("updateHost", {
              ...newUser,
              id: userID
            })
          } else {
            const existingUserID = await fetchUserIDByEmail(email)
            if (existingUserID !== newUser.id) {
              const user = await fetchUser({ userID: existingUserID })
              if (!user)
                throw new Error(
                  `Email ${email} is assigned to a deleted user (ID ${existingUserID})`
                )
              throw new Error(
                `Email ${email} is already assigned to ${user.firstname} ${user.lastname} (ID ${existingUserID})`
              )
            }
            await this.$store.dispatch("updateHost", newUser)
            await db
              .ref(`users/private/${newUser.id}/email`)
              .set(this.emailComputed)
            if (!newUser.super) {
              const update = {}
              update[`0/${newUser.id}`] = null
              update[`1/${newUser.id}`] = true
              await db.ref("access").update(update)
            }
          }
          await this.updateUsers()
        } catch (e) {
          console.error(e)
          alert(e.message)
        }
        this.cancel()
      }

      this.working = false
    },
    getRules() {
      return rules
    }
  }
})
